import React from 'react';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

const SingleStat = ({ label, children }) => <Grid item md={1} sm={2}>
    <Typography variant="overline" gutterBottom>
        {label}
    </Typography>
    <Typography variant="body2" gutterBottom noWrap>
        <span title={children}>{children}</span>
    </Typography>
</Grid>

export default ({
    Stat, OP, HP, RTm, Pur, CT,
    BT, Volt, WL, Vno, eventTime, Cur, WT
}) => {
    const start = moment(eventTime);

    return <div>
        <ListItem>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {start.format('MM/DD/YY h:mm a')}
                    </Typography>
                </Grid>
                <SingleStat label="Stat">{Stat}</SingleStat>
                <SingleStat label="OP">{OP}</SingleStat>
                <SingleStat label="HP">{HP}</SingleStat>
                <SingleStat label="RTm">{RTm}</SingleStat>
                <SingleStat label="Pur">{Pur}</SingleStat>
                <SingleStat label="CT">{CT}</SingleStat>
                <SingleStat label="BT">{BT}</SingleStat>
                <SingleStat label="Volt">{Volt}</SingleStat>
                <SingleStat label="WL">{WL}</SingleStat>
                <SingleStat label="Vno">{Vno}</SingleStat>
                <SingleStat label="Cur">{Cur}</SingleStat>
                <SingleStat label="WT">{WT}</SingleStat>
            </Grid>
        </ListItem>
        <Divider light />
    </div>;
};
