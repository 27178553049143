import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Spinner from './Spinner.js';

import get from './httpClient.js';

const saveToLocalStorage = devices => {
    devices.forEach(({ id, name}) => localStorage.setItem(`${id}-name`, name));
};

const getDevices = () => get(`/api/particle/device`)
    .then(devices => {
        //TODO remove once we are using redux to drive the app bar title
        saveToLocalStorage(devices);

        return devices;
    });

const SimpleDeviceList = ({ devices = [] }) => {
    return (<List component="nav">
        {
            devices.map(
                ({ id, name }) => <Link to={`/device/${id}`}>
                    <ListItem key={id} button>
                        <ListItemText primary={name} />
                    </ListItem>
                </Link>
            )
        }
    </List>);
};

class DeviceList extends Component {
    constructor(props){
        super(props);

        this.state = {
            devices: null,
            error: null,
        };
    }

    componentDidMount(){
        getDevices()
            .then(devices => this.setState({ devices, error: null }))
            .catch(error => () =>{
                 this.setState({ error, devices: null });
                 console.error('Error retrieving devices', error);
            });
    }

    render(){
        const { devices, error } = this.state;

        if(error){
            return <h3>There was an error retrieving device information</h3>;
        }

        if(!devices){
            return <Spinner />;
        }

        return <SimpleDeviceList devices={devices} />;
    }
}

export default DeviceList;
