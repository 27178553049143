import { CognitoAuth } from 'amazon-cognito-auth-js';

const CONFIG = {
    ClientId : '1nvhdluvoe5e0e15fo1de3930n',
    AppWebDomain: 'boostboxh2.auth.us-west-2.amazoncognito.com',
    TokenScopesArray : ['email', 'phone', 'openid', 'profile'],
    RedirectUriSignIn : window.location.origin,
    RedirectUriSignOut : window.location.origin,
};

export default () => (new Promise((ok, bad) => {
    try{
        const auth = new CognitoAuth(CONFIG);
        if(!window.location.search.includes('code=')){
            window.localStorage.setItem('location', window.location.href);
        }

        auth.useCodeGrantFlow();
    
        auth.userhandler = {
            onSuccess: ok,
            onFailure: bad,
        };
        
        auth.parseCognitoWebResponse(window.location.href.replace(/#.*$/, ''));

        auth.getSession();
    }
    catch(error){
        bad(error);
    }
}))
    .then(tokens => {
        if(window.location.search.includes('code=') && tokens.state !== null){
            const defaultLocation = `${window.location.origin}${window.location.pathname}${window.location.hash}`;
            const oldLocation = window.localStorage.getItem('location');
            window.localStorage.removeItem('location');

            window.location.href = oldLocation || defaultLocation;
        }
        
        return tokens;
    });
