import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export default () => <Grid container spacing={24}>
    <Grid item xs={12} justify="center" container>
        <CircularProgress />
    </Grid>
</Grid>;
