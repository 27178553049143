import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

const ID_FROM_PATH_REGEX = /^\/device\/([a-z0-9]{24})$/;

//TODO maybe do this with redux and a setTitle action instead
export default ({ location, history }) => <Typography variant="h6" color="inherit">
    { location.pathname !== '/' && history && backButton(history) }
    { getTitle(location) }
</Typography>;

const backButton = history => <IconButton component="button" onClick={history.goBack}>
    <ArrowBack style={{ color: 'white' }} />
</IconButton>;

const getTitle = location => {
    if(location && ID_FROM_PATH_REGEX.test(location.pathname)){
        const [, id] = location.pathname.match(ID_FROM_PATH_REGEX);

        return localStorage.getItem(`${id}-name`);
    }

    return "Boostbox H2 Devices";
};
