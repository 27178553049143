import axios from 'axios';

import getUser from './getUser.js';

const DEV_DOMAIN = 'https://app-dev.boostboxh2.com';

const getAPIOrigin = () => {
    if(window.location.hostname === 'localhost'){
        return DEV_DOMAIN;
    }

    return window.location.origin;
}

export default url =>
    getUser()
        .then(({ idToken }) => ({ Authorization: idToken.jwtToken }))
        .then(headers => axios.get(`${getAPIOrigin()}${url}`, { headers }))
        .then(({ data }) => data);
