import React, { Component, Fragment } from 'react';
import moment  from 'moment';

import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import FilterList from '@material-ui/icons/FilterList';

import { FixedSizeList } from 'react-window';

import Spinner from './Spinner.js';
import Event from './Event.js';

import get from './httpClient.js';

const Row = ({ index, style, data }) => <div style={style}>
    <Event {...data[index]} />
</div>;

const getDeviceEvents = ({ id, startTime, endTime }) => {
    if(!startTime.toISOString() || !endTime.toISOString()){
        return Promise.reject('Start time and end time may not be empty');
    }

    return get(`/api/device/${id}/event?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`);
};

export default class extends Component {
    constructor(props){
        super(props);

        this.top = React.createRef();

        this.state = {
            id: props.match.params.id,
            events: null,
            startTime: moment().subtract(1, 'day').startOf('day'),
            endTime: moment().startOf('day'),
            width: null,
            height: null,
            rowSize: null,
            controlsHiddenInLowRes: true,
        };

        this.resize = this.resize.bind(this);
    }

    render(){
        return <Fragment>
            {this.error()}
            <List>
                { this.controls() }
                <div style={{ height: 0, margin:0 , padding: 0 }} ref={this.top} />
                { this.spinner() }
                { this.noResults() }
                { this.list() }
            </List>
        </Fragment>;
    }

    error(){
        const { error } = this.state;

        return !error ? null : <Typography variant="h6" gutterBottom>
            There was an error while retrieving device events.
            Please ensure your start date is before your end date
            and does not span a range more than three weeks.
        </Typography>;
    }

    spinner(){
        const { events } = this.state;

        return events ? null : <Spinner />;
    }

    noResults(){
        const { events } = this.state;

        return (!events || events.length > 0) ? null : <Grid container spacing={24}>
            <Grid item xs={12} justify="center" container>
                <Typography variant="body2" gutterBottom>
                    No records found
                </Typography>
            </Grid>
        </Grid>;
    }

    list(){
        const {
            events,
            width, height, rowSize,
        } = this.state;

        if(!events || !width || !height || !rowSize){
            return null;
        }

        return <FixedSizeList
            height={height}
            itemCount={events.length}
            itemSize={rowSize}
            width={width}
            itemData={events}>
            {Row}
        </FixedSizeList>;
    }

    controlsToggle(){
        return <IconButton component="button" onClick={() => this.setState(
                ({ controlsHiddenInLowRes }) => ({
                    controlsHiddenInLowRes: !controlsHiddenInLowRes
                }), () => setImmediate(this.updateSize.bind(this))
            )}>
            <FilterList />
        </IconButton>;
    }

    controls(){
        const {
            error, startTime, endTime,
            controlsHiddenInLowRes,
            width,
        } = this.state;

        if(width < 600 && controlsHiddenInLowRes){
            return <Fragment>
                <Grid item xs={12}>
                    { this.controlsToggle() }
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Fragment>;
        }

        return <Grid container spacing={24}>
            { width < 600 && <Grid item xs={12}>
                { this.controlsToggle() }
            </Grid> }
            <Grid item xs={12} sm={6}>
                <TextField
                    id="startTime"
                    label="Start Date"
                    type="date"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={!!error || !startTime.isValid()}
                    value={startTime.format('YYYY-MM-DD')}
                    onChange={({ target }) => this.setState({
                        startTime: moment(target.value),
                    })}
                    InputLabelProps={{
                        shrink: true,
                    }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="endTime"
                    label="End Date"
                    type="date"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={!!error || !endTime.isValid()}
                    value={endTime.format('YYYY-MM-DD')}
                    onChange={({ target }) => this.setState({
                        endTime: moment(target.value),
                    })}
                    InputLabelProps={{
                        shrink: true,
                    }} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>;
    }

    componentDidMount(){
        this.fetchEvents();

        this.updateSize();

        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.resize);
    }

    componentDidUpdate(_, oldState){
        const { startTime, endTime } = this.state;

        if(startTime.diff(oldState.startTime) === 0 && endTime.diff(oldState.endTime) === 0){
            return;
        }

        if(!startTime.isValid() || !endTime.isValid()){
            return;
        }

        clearTimeout(this.fetchTimeout);
        this.fetchTimeout = setTimeout(this.fetchEvents.bind(this), 300);
    }

    updateSize(){
        const { y: top, width } = this.top.current.getBoundingClientRect();
        const height = window.innerHeight - top;
        const windowWidth = window.innerWidth;

        this.setState({
            width, height,
            rowSize: windowWidth >= 960 ? 140 :
                windowWidth >= 400 ? 227 :
                windowWidth >= 300 ? 315 : 403,
        });
    }

    resize(){
        clearTimeout(this.sizingTimeout);

        this.sizingTimeout = setTimeout(this.updateSize.bind(this), 100);
    }

    fetchEvents(){
        const { id, startTime, endTime } = this.state;

        this.setState({ events: null });

        getDeviceEvents({ id, startTime, endTime })
            .then(events => this.setState({ events, error: null }))
            .catch(error => {
                this.setState({ error, events: []});
                console.error(`Error while fetching device ${id} events`, error);
            });
    }
};
