import React from 'react';
import ReactDOM from 'react-dom';

import 'es6-promise/auto';
import 'whatwg-fetch';

import App from './App.js';
import getUser from './getUser.js';

const getRoot = () => {
    const existing = document.getElementById('root');

    if(existing){
        return existing;
    }

    const element = document.createElement('div');

    element.setAttribute('id', 'root');

    document.body.append(element);

    return element;
};

getUser()
    .then(() => {
        ReactDOM.render(<App />, getRoot());
    })
    .catch((...args) => {
        console.error('There was an error', ...args);
        ReactDOM.render(
            <h1>We applogize for the error...</h1>,
            getRoot()
        );
    });
