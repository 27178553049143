import React from 'react';
import { object } from 'prop-types';
import { HashRouter as Router, Route } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import DeviceList from './DeviceList.js';
import DeviceLog from './DeviceLog.js';
import Header from './Header.js';


const styles = {
    root: {
      flexGrow: 1,
    },
  };

const App = ({ classes }) => (
    <Router>
        <div className={classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <Route component={Header} />
                </Toolbar>
            </AppBar>
            <div style={{ margin: 10 }}>
                <Route path="/" exact component={DeviceList} />
                <Route path="/device/:id" component={DeviceLog} />
            </div>
        </div>
    </Router>
);

App.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(App);
